import React from 'react';
import './Sidebar.css'
import passport from '../../assets/passport.png'
import { Link } from 'react-router-dom'


const Sidebar = ({sidebarOpen, setSidebar}) => {

    const onClickSidebar = () => {
        if(document.body.clientWidth < 978) {
            setSidebar(false)
        } 
    }

    return (
        <div className={sidebarOpen ? "sidebar_responsive" : ""} id="sidebar">
            <div className="sidebar__title">
                <div className="sidebar__img">
                    <img src={passport} alt="logo" />
                    <h1>Shahar Jacob</h1>
                </div>
            </div>
            <div className="sidebar__menu">
                <div className="sidebar__link active_menu_link" onClick={() => onClickSidebar()}>
                    <i className="fa fa-home"></i>
                    <Link to="/" style={{textDecoration: 'none'}}>Home</Link>
                </div>
                <h2>About</h2>
                <div className="sidebar__link" onClick={() => onClickSidebar()}>
                    <i className="far fa-address-card"></i>
                    <Link to="/cv" style={{textDecoration: 'none'}}>CV</Link>
                </div>
                <h2>Projects</h2>
                <div className="sidebar__link" onClick={() => onClickSidebar()}>
                    <i className="fa fa-wrench"></i>
                    <Link to="/personalstyle" style={{textDecoration: 'none'}}>Personal Style Synthesis</Link>
                </div>
                <div className="sidebar__link" onClick={() => onClickSidebar()}>
                    <i className="fa fa-wrench"></i>
                    <Link to="/puzzleassembly" style={{textDecoration: 'none'}}>Puzzle Assembling</Link>
                </div>
                <div className="sidebar__link" onClick={() => onClickSidebar()}>
                    <i className="fa fa-wrench"></i>
                    <Link to="/fakeorreal" style={{textDecoration: 'none'}}>Fake or Real</Link>
                </div>
                <div className="sidebar__link" onClick={() => onClickSidebar()}>
                    <i className="fa fa-wrench"></i>
                    <Link to="/doorguard" style={{textDecoration: 'none'}}>The Door Guard</Link>
                </div>
            </div>
        </div>
    )
}

export default Sidebar;