import './Card.css'
import '../main/Main.css'


const CvCardMilitary = () => {

    return (
        <table>
            <tbody>
                <tr>
                    <td style={{verticalAlign: 'top', width: '50px'}}>
                        <i className="fas fa-star-of-david fa-2x text-lightblue"></i>
                    </td>
                    <td>
                        <div className="card-inner-project-pdf">
                            <p className="text-primary-cv">Military service</p>
                        </div>
                        <div className="card-inner-description-project-pdf">
                            <p className="text-description-cv">
                                2011 – 2014: 
                                <span className="text-description-cv" style={{paddingLeft: '3em'}}>
                                    Combat solider in special unit of the Intelligence Forces.
                                </span>
                            </p>
                            <li className="text-description-cv" style={{paddingLeft: '9.5em'}}>
                                Working in a team.
                            </li>
                            <li className="text-description-cv" style={{paddingLeft: '9.5em'}}>
                                Participation in special operations as a combat solider.
                            </li>
                            <li className="text-description-cv" style={{paddingLeft: '9.5em'}}>
                                Responsible of the field of navigators and of the physical fitness in the team.
                            </li>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default CvCardMilitary;


