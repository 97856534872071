import './Card.css'
import '../main/Main.css'
import { Link } from "react-router-dom";


const CvCardExperience = () => {

    return (
        <table>
            <tbody>
                <tr>
                    <td style={{verticalAlign: 'top', width: '50px'}}>
                        <i className="fas fa-briefcase fa-2x text-lightblue"></i>
                    </td>
                    <td>
                        <div className="card-inner-project-pdf">
                            <p className="text-primary-cv">Experience</p>
                        </div>
                        <div className="card-inner-description-project-pdf">
                            <p className="text-description-cv">
                                5/2019 – present: 
                                <span className="text-description-dark-cv" style={{paddingLeft: '3em'}}>
                                    Algorithm developer in Samsung SIRC <span className="text-description-cv">(student job).</span>
                                </span>
                            </p>
                            <li className="text-description-cv" style={{paddingLeft: '9.5em'}}>
                                Embedded development of image processing algorithms for camera sensors.
                            </li>
                            <li className="text-description-cv" style={{paddingLeft: '9.5em'}}>
                                Writing algorithms in C++ and Python and integrating them in the sensor hierarchy.
                            </li>
                            <li className="text-description-cv" style={{paddingLeft: '9.5em'}}>
                                Refactoring and improving existing algorithms by adding new features.
                            </li>
                            <li className="text-description-cv" style={{paddingLeft: '9.5em'}}>
                                Development tools for the work environment (infrastructures) – including Full-Stack.
                            </li>

                            <p className="text-mini-primary-cv">
                                Projects:
                            </p>
                            <li className="text-description-cv" style={{paddingLeft: '9.5em'}}>
                                <Link to="/personalstyle" style={{textDecoration: 'none'}}>Personal Style Synthesis:</Link>
                                &nbsp;&nbsp;A new approach for computer-based stylization.
                            </li>
                            <li className="text-description-cv" style={{paddingLeft: '9.5em'}}>
                                <Link to="/fakeorreal" style={{textDecoration: 'none'}}>Fake or Real:</Link>
                                &nbsp;&nbsp;Recognize between real and fake faces which generated by StyleGAN. Developed for android by Java.
                            </li>
                            <li className="text-description-cv" style={{paddingLeft: '9.5em'}}>
                                <Link to="/puzzleassembly" style={{textDecoration: 'none'}}>Puzzle Assembling:</Link>
                                &nbsp;&nbsp;Simplify the docking problem to 2D problem by using deep learning.
                            </li>
                            <li className="text-description-cv" style={{paddingLeft: '9.5em'}}>
                                <Link to="/doorguard" style={{textDecoration: 'none'}}>The Door Guard:</Link>
                                &nbsp;&nbsp;Identify cyclists in the side-mirror of the vehicle and alert to the driver, using a camera.
                            </li>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default CvCardExperience;


