import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Main from './components/main/Main'

ReactDOM.render(
  <React.StrictMode>
    <App page={<Main />} />
  </React.StrictMode>,
  document.getElementById('root')
);
