import { useEffect} from 'react'
import { Link, useHistory } from 'react-router-dom'
import './Main.css'
import hello2 from '../../assets/passport2.png'


const Main = () => {

    const history = useHistory()

    useEffect(() => {
        if (history) {
            if (history.action === 'POP') {
                history.replace(history.location.pathname);
            }
        }
    })


    return (
        <main>
            <div className="main__container">
                <div className="main__title">
                    <img src={hello2} alt="hello" />
                    <div className="main__greeting">
                        <h1>Shahar Jacob</h1>
                        <div className="passport_details">
                            <p className="text-description-p">Email: shahar.j12@gmail.com</p>
                        </div>
                        <div className="passport_details">
                            <p className="text-description-p">Phone: 0508810227</p>
                        </div>
                        <div className="passport_details">
                            <p className="text-description-p">Age: 29</p>
                        </div>
                    </div>
                </div>

                <div className="main__cards">

                    <Link to="/personalstyle" style={{textDecoration: 'none'}}>
                        <div className="card">
                            <i className="fas fa-toolbox fa-2x text-lightblue"></i>
                            <div className="card_inner">
                                <p className="text-primary-p">Personal Style Synthesis</p>
                            </div>
                            <div className="card_inner_description">
                                <p className="text-description-p">A new approach for computer-based stylization.</p>
                            </div>
                        </div>
                    </Link>

                    <Link to="/puzzleassembly" style={{textDecoration: 'none'}}>
                        <div className="card">
                            <i className="fas fa-toolbox fa-2x text-red"></i>
                            <div className="card_inner">
                                <p className="text-primary-p">Puzzle Assembling</p>
                            </div>
                            <div className="card_inner_description">
                                <p className="text-description-p">Simplify the docking problem to 2D problem by using deep learning.</p>
                            </div>
                        </div>
                    </Link>

                    <Link to="/fakeorreal" style={{textDecoration: 'none'}}>
                    <div className="card">
                        <i className="fas fa-toolbox fa-2x text-yellow"></i>
                        <div className="card_inner">
                            <p className="text-primary-p">Fake or Real</p>
                        </div>
                        <div className="card_inner_description">
                            <p className="text-description-p">Recognize between real and fake faces which generated by StyleGAN. Developed for android by Java.</p>
                        </div>
                    </div>
                    </Link>

                    <Link to="/doorguard" style={{textDecoration: 'none'}}>
                    <div className="card">
                        <i className="fas fa-toolbox fa-2x text-green"></i>
                        <div className="card_inner">
                            <p className="text-primary-p">The Door Guard</p>
                        </div>
                        <div className="card_inner_description">
                            <p className="text-description-p">Identify cyclists in the side-mirror of the vehicle and alert to the driver, using a camera.</p>
                        </div>
                    </div>
                    </Link>
                </div>
            </div>
        </main>

    )
}

export default Main;