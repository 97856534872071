import "./Navbar.css";

const Navbar = ({sidebarOpen, setSidebar}) => {
    return (
        <nav className="navbar">
            <div className="nav_icon" onClick={() => setSidebar(!sidebarOpen)}>
                <i className="fa fa-bars"></i>
            </div>
        </nav>
    )
}

export default Navbar;