import './Card.css'
import '../main/Main.css'


const CvCardSummary = () => {

    return (
        <table>
            <tbody>
                <tr>
                    <td style={{verticalAlign: 'top', width: '50px'}}>
                        <i className="fas fa-edit fa-2x text-lightblue"></i>
                    </td>
                    <td>
                        <div className="card-inner-project-pdf">
                            <p className="text-primary-cv">Summary</p>
                        </div>
                        <div className="card-inner-description-project-pdf">
                            <li className="text-description-cv" style={{paddingLeft: '3em'}}>Graduate of Electrical and Computer Engineering from The Hebrew University of Jerusalem.</li>
                            <li className="text-description-cv" style={{paddingLeft: '3em'}}>Work experience in algorithms development in C++ and Python.</li>
                            <li className="text-description-cv" style={{paddingLeft: '3em'}}>My interests are mostly in Deep Learning, Image Processing and Algorithms.</li>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default CvCardSummary;


