import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import '../card/Card.css'
import CvCardSummary from '../card/CvCardSummary'
import CvCardEducation from '../card/CvCardEducation'
import CvCardExperience from '../card/CvCardExperience'
import CvCardMilitary from '../card/CvCardMilitary'
import CvCardAdditional from '../card/CvCardAdditional'
import CvCardLanguages from '../card/CvCardLanguages'


const CV = () => {

    const history = useHistory()

    useEffect(() => {
        if (history) {
            if (history.action === 'POP') {
                history.replace(history.location.pathname);
            }
        }
    })


    return (
        <main>
            <div className="main__container">

                    <CvCardSummary />
                    <br/>
                    <CvCardEducation />
                    <br/>
                    <CvCardExperience />
                    <br/>
                    <CvCardMilitary />
                    <br/>
                    <CvCardAdditional />
                    <br/>
                    <CvCardLanguages />

            </div>
        </main>
    )
}

export default CV;
