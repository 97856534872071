import { useEffect} from 'react'
import { useHistory } from 'react-router-dom'
import PDF from './PDF'
import CardDoorGuardInfo from '../card/CardDoorGuardInfo'

const DoorGuard = ({width}) => {

  const history = useHistory()
  useEffect(() => {
    if (history) {
        if (history.action === 'POP') {
            history.replace(history.location.pathname);
        }
    }
  })

  return (
    <PDF 
      path='/DoorGuard.pdf' 
      card={<CardDoorGuardInfo width={width} />} 
      width={width} />
  );
}

export default DoorGuard