import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import PDF from './PDF'
import CardPuzzleAssemblyInfo from '../card/CardPuzzleAssemblyInfo'

const PuzzleAssembly = ({width}) => {

  const history = useHistory()

  useEffect(() => {
    if (history) {
        if (history.action === 'POP') {
            history.replace(history.location.pathname);
        }
    }
  })

  return (
    <PDF 
      path='/PuzzleAssembly.pdf' 
      card={<CardPuzzleAssemblyInfo width={width} />} 
      width={width} />
  );
}

export default PuzzleAssembly