import './Card.css'
import '../main/Main.css'


const CvCardLanguages = () => {

    return (
        <table>
            <tbody>
                <tr>
                    <td style={{verticalAlign: 'top', width: '50px'}}>
                        <i className="fas fa-globe-americas fa-2x text-lightblue"></i>
                    </td>
                    <td>
                        <div className="cardiinner-project-pdf">
                            <p className="text-primary-cv">Languages</p>
                        </div>
                        <div className="card-inner-description-project-pdf">
                            <p className="text-description-cv">
                                Hebrew: Mother language
                            </p>
                        </div>
                        <div className="card-inner-description-project-pdf">
                            <p className="text-description-cv">
                                English: High level proficiency
                            </p>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default CvCardLanguages;


