import '../main/Main.css'
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const PDF = ({path, card, width}) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumberToGo, setPageNumberToGo] = useState(1);
  const [zoom, setZoom] = useState(1);
  

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }

  const nextPage = () => {
    if (pageNumberToGo < numPages){
        setPageNumberToGo(pageNumberToGo + 1);
    } 
  }

  const PreviousPage = () => {
    if (pageNumberToGo > 1) {
        setPageNumberToGo(pageNumberToGo - 1);
    } 
  }

  const ZoomIn = () => {
    if (zoom < 3) {
        setZoom(zoom + 0.1);
    } 
  }

  const ZoomOut = () => {
    if (zoom > 0.2) {
        setZoom(zoom - 0.1);
    } 
  }

  const UpdateFuturePageToGo = (page) => {
    var num = parseInt(page);
    if (isNaN(num)){
        return;
    }
    if (num < 1 || num > numPages){
        return;
    }
    setPageNumberToGo(num);
}



  return (
    <div className="pdf__container">
        <div className="top-pdf" style={{width: width}}>
            {card}
        </div>
        <div style={{width: width}}>
            <Document
                file={path}
                onLoadSuccess={(onDocumentLoadSuccess)}
                >
                <Page 
                    pageNumber={pageNumberToGo} 
                    scale={zoom}
                    width={width} />
            </Document>
        </div>
        <div className="bottom-buttons" style={{width: width, paddingTop: '15px'}}>
            <span style={{textAlign: 'left'}}>
                <span className="cursor"><i className={width < 489 ? "fas fa-search-plus text-gray": "fas fa-search-plus fa-2x text-gray"} onClick={() => ZoomIn()}></i></span>&nbsp;&nbsp;
                <span className="cursor"><i className={width < 489 ? "fas fa-search-minus text-gray" : "fas fa-search-minus fa-2x text-gray"} onClick={() => ZoomOut()}></i></span>
            </span>
            <span style={{textAlign: 'center'}}>
                <input 
                    className="form__pages" 
                    placeholder={pageNumberToGo} 
                    type="text" 
                    value={pageNumberToGo} 
                    onChange={(e) => UpdateFuturePageToGo(e.target.value)} /> / {numPages}
            </span>
            <span style={{textAlign: 'right'}}>
                <span className="cursor"><i className={width < 489 ? "far fa-arrow-alt-circle-left text-gray" : "far fa-arrow-alt-circle-left fa-2x text-gray"} onClick={() => PreviousPage()}></i></span>&nbsp;&nbsp;
                <span className="cursor"><i className={width < 489 ? "far fa-arrow-alt-circle-right text-gray" : "far fa-arrow-alt-circle-right fa-2x text-gray"} onClick={() => nextPage()}></i></span>
            </span>
        </div>
    </div>

  );
}

export default PDF