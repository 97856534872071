import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import PDF from './PDF'
import CardFakeOrRealInfo from '../card/CardFakeOrRealInfo'

const FakeOrReal = ({width}) => {

  const history = useHistory()

  useEffect(() => {
    if (history) {
        if (history.action === 'POP') {
            history.replace(history.location.pathname);
        }
    }
  })

  return (
    <PDF 
      path='/FakeOrReal.pdf' 
      card={<CardFakeOrRealInfo width={width} />} 
      width={width} />
  );
}

export default FakeOrReal