import {useState, useEffect} from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Navbar from './components/navbar/Navbar'
import Sidebar from './components/sidebar/Sidebar'
import CV from './components/cv/cv'
import PersonalStyle from './components/pdf/PersonalStyle'
import PuzzleAssembly from './components/pdf/PuzzleAssembly'
import FakeOrReal from './components/pdf/FakeOrReal'
import DoorGuard from './components/pdf/DoorGuard'
import Main from './components/main/Main'



const App = () => {

  const [PDFWidth, setPDFWidth] = useState(Math.floor(document.body.clientWidth * 0.78))
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const setSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  }

  useEffect(() => {
    function handleResize() {
        if(document.body.clientWidth < 489) {
            setPDFWidth(Math.floor(document.body.clientWidth * 0.8))
        }
        else if (document.body.clientWidth < 978) {
            setPDFWidth(Math.floor(document.body.clientWidth * 0.9))
        }
        else {
            setPDFWidth(Math.floor(document.body.clientWidth * 0.78))
        }
        
    }
    window.addEventListener('resize', handleResize)
  })

  return (
    <Router>
      <div className="container">
        <Navbar sidebarOpen={sidebarOpen} setSidebar={setSidebar} />
        <Switch>
          <Route path='/' exact component={Main} />
          <Route path='/cv' component={CV} />
          <Route path='/personalstyle'>
            <PersonalStyle width={PDFWidth} />
          </Route>
          <Route path='/puzzleassembly'>
            <PuzzleAssembly width={PDFWidth} />
          </Route>
          <Route path='/fakeorreal'>
            <FakeOrReal width={PDFWidth} />
          </Route>
          <Route path='/doorguard'>
            <DoorGuard width={PDFWidth} />
          </Route>
        </Switch>
        <Sidebar sidebarOpen={sidebarOpen} setSidebar={setSidebar} />

      </div>
    </Router>
  );
}

export default App;
