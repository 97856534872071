import './Card.css'
import '../main/Main.css'
import { useState } from 'react';


const CardFakeOrRealInfo = ({width}) => {

    const [displayDescription, setDisplayDescription] = useState("flex");
    const [displayPlus, setDisplayPlus] = useState("none");
    const [iconDescription, setIconDescription] = useState(width < 489 ? "fas fa-minus text-gray" : "fas fa-minus fa-2x text-gray");
    const [isHideMode, setIsHideMode] = useState("false");


    const onDescriptionClick = () => {
        if (isHideMode === "false") {
            setDisplayDescription("none");
            setDisplayPlus("flex")
            setIconDescription(width < 489 ? "fas fa-plus text-gray" : "fas fa-plus fa-2x text-gray")
            setIsHideMode("true")
        }
        else {
            setDisplayDescription("flex");
            setDisplayPlus("none")
            setIconDescription(width < 489 ? "fas fa-minus text-gray" : "fas fa-minus fa-2x text-gray")
            setIsHideMode("false")
        }
    }

    return (
        <div>
            <div className="card-project-pdf" style={{display: displayDescription, width: width - 28}}>
                <table>
                    <tbody>
                        <tr>
                            <td className="project-description">
                                <i className="fab fa-youtube fa-2x text-red"></i>
                            </td>
                            <td>
                                <div className="card-inner-description-project-pdf">
                                    <p className="text-description-p">
                                        <a href="https://youtu.be/ESZ2-jqR44U" target="_blank">
                                            https://youtu.be/ESZ2-jqR44U
                                        </a>
                                    </p>
                                </div>
                            </td>
                            <td rowSpan={2} className="description-icon">
                                <i className={iconDescription} style={{cursor: "pointer"}} onClick={() => onDescriptionClick()}></i>
                            </td>
                        </tr>
                        <tr>
                            <td className="project-description">
                                <i className="far fa-file-pdf fa-2x text-red"></i>
                            </td>
                            <td>
                                <div className="card-inner-description-project-pdf">
                                    <p className="text-description-p">
                                        <a href="https://drive.google.com/file/d/1qZXhJ9yVlIrGgRiYKTSl6LFbdPXVesJP/view?usp=sharing" target="_blank">
                                            https://drive.google.com/file/d/1qZXhJ9yVlIrGgRiYKTSl6LFbdPXVesJP/view?usp=sharing
                                        </a>
                                    </p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style={{display: displayPlus, flexDirection: "column"}}>
                <table>
                    <tbody>
                        <tr>
                            <td className="description-icon">
                                <i className={iconDescription} style={{cursor: "pointer"}} onClick={() => onDescriptionClick()}></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
                
            </div>
        </div>
        

    )
}

export default CardFakeOrRealInfo;


