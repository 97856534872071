import './Card.css'
import '../main/Main.css'


const CvCardEducation = () => {

    return (
        <table>
            <tbody>
                <tr>
                    <td style={{verticalAlign: 'top', width: '50px'}}>
                        <i className="fas fa-user-graduate fa-2x text-lightblue"></i>
                    </td>
                    <td>
                        <div className="card-inner-project-pdf">
                            <p className="text-primary-cv">Education</p>
                        </div>
                        <div className="card-inner-description-project-pdf">
                            <p className="text-description-cv">
                                2020 – present: 
                                <span className="text-description-cv" style={{paddingLeft: '3em'}}>
                                    1𝑠𝑡 year student for M.Sc. in Computer Science, Hebrew University, Jerusalem.
                                </span>
                            </p>
                            <p className="text-description-cv">
                                2016 – 2020:
                                <span className="text-description-cv" style={{paddingLeft: '4em'}}>
                                B.Sc. in Electrical and Computer Engineering, Hebrew University, Jerusalem, GPA 88.
                                </span>
                            </p>
                            <br />
                            <p className="text-mini-primary-cv">
                                Professional knowledge:
                            </p>
                            <li className="text-description-cv" style={{paddingLeft: '9.5em'}}>Languages – Python, Java, C, C++, JavaScript.</li>
                            <li className="text-description-cv" style={{paddingLeft: '9.5em'}}>Technologies – Git, TensorFlow, PyTorch, Flask, Firebase, React.</li>
                            <li className="text-description-cv" style={{paddingLeft: '9.5em'}}>Operation systems – Windows, Linux.</li>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default CvCardEducation;


